@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-base text-gray-600 bg-slate-50 antialiased;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
  margin-left: 5px;
}
